import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas } from 'react-three-fiber';

import database_ from './data/database.json';
import PhotosphereViewer, { PositionState } from './PhotosphereViewer/PhotosphereViewer';
import ViewpointMarkers from './ViewpointMarkers';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import { dark } from '@material-ui/core/styles/createPalette';
import { animate, MotionValue } from 'framer-motion';
import Sidebar from './Sidebar/Sidebar';

import IntroViewer from './IntroViewer';
import UseCaseAreaViewer from './UseCaseAreaViewer';
import InteractiveAreaViewer from './InteractiveAreaViewer';
import Overview from './Overview';
import Graph from 'node-dijkstra';
// @ts-ignore
import samsungLoop from './data/samsungLoop.mp3';
import GroundCursor from './PhotosphereViewer/GroundCursor';

import musicOffIcon from './data/images/soundOff.svg';
import musicOnIcon from './data/images/soundOn.svg';
import { useResponsiveFontSize, useResponsiveSize } from './useResponsiveFontSize';
import { useUpdateSidebar } from './useUpdateSidebar';
import TopMenu from './TopMenu';

declare module "@material-ui/core/styles/createBreakpoints" {
  interface BreakpointOverrides {
    xl: false;
  }
}

const theme = createMuiTheme({
  palette: {
    ...dark,
    background: {
      default: "#000000",
      paper: "#000000",
    }
  },
  typography: {
    fontFamily: "SamsungSharpSans-Medium",
    button: {
      textTransform: 'none',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 535,
      md: 960,
      lg: 1280,
    },
  },
});

export type slideTypeProducts = {
  products: string[],
}

export type UseCase = {
  name: string,
  targetGroup: string,
  productSlide: slideTypeProducts,
  id: string,
};

export type Area = {
  name: string,
  color: {
    r: number,
    g: number,
    b: number,
  },
  overrideTextColor?: {
    r: number,
    g: number,
    b: number,
  },
  viewpointObject: string,
  showInMenu: boolean,
  mapPosition: {
    x: number,
    y: number,
  },
  cameraAngle1: number,
  cameraAngle2: number,
  cameraFov: number,
};

export type UseCaseArea = Area & {
  type: "useCase",
  useCases: UseCase[],
};

export type InteractiveArea = Area & {
  type: "interactive",
  states: {
    [key: string]: {
      buttons: {
        left: number,
        width: number,
        bottom: number,
        height: number,
      }[],
    },
  },
};

export type Area_any = UseCaseArea | InteractiveArea;

export type ProductInfos = {
  [key: string]: {
    link?: string,
  },
};

type databaseType = {
  rayCastSettings: {
    floorContainer: string,
    wallContainer: string,
  },
  overviewCamera: {
    object: string,
    fov: number,
    near: number,
    far: number,
  },
  targetGroups: {
    [key: string]: {
      name: string,
    },
  },
  productInfos: ProductInfos,
  areas: {
    [key: string]: Area_any,
  },
  blockedRoutes: {
    anchor: string,
    routes: string[],
  }[],
};

type sidebarStateType_Open = {
  state: "open",
  area: Area_any,
};

type sidebarStateType_Close = {
  state: "close",
  area?: Area_any,
};

type sidebarStateType_Opening = {
  state: "opening",
  motionValue: MotionValue<number>,
  area: Area_any,
}

type sidebarStateType_Closing = {
  state: "closing",
  motionValue: MotionValue<number>,
  area: Area_any,
}

export type sidebarStateType = sidebarStateType_Open | sidebarStateType_Close | sidebarStateType_Opening | sidebarStateType_Closing;

function App() {
  const [model, setModel] = useState<THREE.Group>();
  const photosphereGroup = useMemo(() => model?.getObjectByName("Kameras"), [model]);

  const database = useMemo<databaseType>(() => database_ as databaseType, []);

  const route = useMemo(() => {
    if(!photosphereGroup) return;
    const route = new Graph();
    for(let node of photosphereGroup.children) {
      const connections: {
        [key: string]: number,
      } = {};
      for(let nodeConnection of photosphereGroup.children) {
        if(node === nodeConnection) continue;
        connections[nodeConnection.name] = Math.pow(node.position.distanceTo(nodeConnection.position), 1.5);
        const blocked = database.blockedRoutes.find(rf => (rf.anchor === node.name && rf.routes.includes(nodeConnection.name)) || (rf.anchor === nodeConnection.name && rf.routes.includes(node.name)));
        if(blocked !== undefined) connections[nodeConnection.name] *= 9999;
      }
      route.addNode(node.name, connections);
    }
    return route;
  }, [photosphereGroup, database]);

  const [positionState, setPositionState] = useState<PositionState>();

  const forceResizeRef = useRef<() => void>();
  const sidebarRef = useRef<HTMLDivElement>();

  const getAreaIdFromObjectName = useMemo(() => (name: string) => {
    const currAreaName = Object.keys(database.areas).find(a => database.areas[a].viewpointObject === name);
    return currAreaName;
  }, [database]);
  const getAreaFromObjectName = useMemo(() => (name: string) => {
    const currAreaName = getAreaIdFromObjectName(name);
    return currAreaName ? database.areas[currAreaName] : undefined;
  }, [database, getAreaIdFromObjectName]);

  const [sidebarState, setSidebarState] = useState<sidebarStateType>({
    state: "close",
  });

  const [moveToPointerDirectionFunc, setMoveToPointerDirectionFunc] = useState<() => void>(() => {});

  const [currAreaId, currArea] = useMemo(() => {
    if(positionState?.step === "stationary_03done") {
      return [getAreaIdFromObjectName(positionState.object.name), getAreaFromObjectName(positionState.object.name)];
    }
    return [null, null];
  }, [positionState, getAreaIdFromObjectName, getAreaFromObjectName]);

  const sidebarWidth = sidebarState.area?.type === "interactive" ? 25 : 30;

  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  const updateSidebar = useMemo(() => (state: "opening" | "closing" | "open" | "close", motionValue?: MotionValue) => {
    if(sidebarRef.current) {
      if((state === "opening" || state === "closing") && motionValue !== undefined) {
        if(state === "opening") {
          sidebarRef.current.style.right = upSM ? ("-" + (sidebarWidth*(1-motionValue.get())).toString() + "%") : "0";
          sidebarRef.current.style.top = upSM ? "unset" : ((100*(1-motionValue.get())).toString() + "%");
          sidebarRef.current.style.width = upSM ? (sidebarWidth + "%") : "100%";
        }
        if(state === "closing") {
          sidebarRef.current.style.right = upSM ? ("-" + (sidebarWidth*(1-motionValue.get())).toString() + "%") : "0";
          sidebarRef.current.style.top = upSM ? "unset" : ((100*(1-motionValue.get())).toString() + "%");
          sidebarRef.current.style.width = upSM ? (sidebarWidth + "%") : "100%";
        }
      } else {
        sidebarRef.current.style.right = upSM ? ("-" + (state === "open" ? 0 : 100).toString() + "%") : "0";
        sidebarRef.current.style.top = upSM ? "0" : ("-" + (state === "open" ? 0 : 100).toString() + "%");
        sidebarRef.current.style.width = upSM ? (sidebarWidth + "%") : "100%";
      }
    }
    if(invalidateRef.current) invalidateRef.current();
  }, [upSM, sidebarWidth]);
  const updateSidebarRef = useRef(updateSidebar);
  updateSidebarRef.current = updateSidebar;
  useEffect(() => {
    if(updateSidebarRef.current) updateSidebarRef.current(sidebarState.state);
  }, [sidebarState, upSM]);

  useEffect(() => {
    const duration = 500;
    if(positionState?.step === "moving_03transitioning") {
      const firstObject = positionState.objects[0];
      const lastObject = positionState.objects[positionState.objects.length-1];

      const firstArea = getAreaFromObjectName(firstObject.name);
      const lastArea = getAreaFromObjectName(lastObject.name);

      if(lastArea) {
        if(sidebarState?.state === "close") {
          setTimeout(() => {
            const mv = new MotionValue(0);
            animate(mv, 1, {
              type: "tween",
              duration: duration / 1000,
              onUpdate: () => {
                updateSidebarRef.current("opening", mv);
              },
              onComplete: () => {
                setSidebarState({
                  state: "open",
                  area: lastArea,
                });
                updateSidebarRef.current("open");
              },
            });
            setSidebarState({
              state: "opening",
              motionValue: mv,
              area: lastArea,
            });
          }, positionState.duration * 1000 - duration);
        } else {
          if(!(sidebarState.state === "open" && sidebarState.area === lastArea)) {
            setSidebarState({
              state: "open",
              area: lastArea,
            });
          }
        }
      }
      if(!lastArea) {
        if(sidebarState?.state === "open") {
          const mv = new MotionValue(1);
          animate(mv, 0, {
            type: "tween",
            duration: duration / 1000,
            onUpdate: () => {
              updateSidebarRef.current("closing", mv);
            },
            onComplete: () => {
              setSidebarState({
                state: "close",
              });
              updateSidebarRef.current("close");
            },
          });
          setSidebarState({
            state: "closing",
            motionValue: mv,
            area: firstArea as Area_any,
          });
        } else {
          if(
            !(sidebarState.state === "close" && sidebarState.area === undefined) &&
            sidebarState.state !== "closing"
          ) {
            setSidebarState({
              state: "close",
            });
          }
        }
      }
    }
  }, [positionState, getAreaFromObjectName, currArea, sidebarState]);

  useEffect(() => {
    if(sidebarState.state === "close") {
      setCurrUseCase(undefined);
    }
  }, [sidebarState]);

  const [currUseCase, setCurrUseCase] = useState<UseCase>();
  const [showProducts, setShowProducts] = useState(false);
  useEffect(() => {
    setShowProducts(false);
  }, [currUseCase]);

  const [showIntro, setShowIntro] = useState(true);
  const [playIntro, setPlayIntro] = useState(false);
  const [intro1Finished, setIntro1Finished] = useState(false);
  const [intro2Finished, setIntro2Finished] = useState(false);

  const invalidateRef = useRef<() => void>();

  useEffect(() => {
    setCurrUseCase(undefined);
  }, [positionState]);

  const [showOverview, setShowOverview] = useState(false);

  const toggleOverview = () => {
    if(showOverview && !positionState) {
      setPositionState({
        step: "stationary_01start",
        object: model?.getObjectByName("05") as THREE.Object3D,
      });
    }
    setShowIntro(false);
    setShowOverview(o => !o);
  };

  const intro1VideoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const [start3d, setStart3d] = useState(false);
  useEffect(() => {
    if(playIntro) {
      setTimeout(() => {
        setStart3d(true);
      }, 250);
    }
  }, [playIntro]);

  const fadeAudio = (direction: "out" | "in", duration = 1) => {
    const maxVolume = 0.5;
    if(!audioRef.current) return;
    animate(audioRef.current.volume, direction === "out" ? 0 : maxVolume, {
      type: "tween",
      duration,
      onUpdate: v => {
        if(!audioRef.current) return;
        audioRef.current.volume = v;
      },
      onComplete: () => {
        if(!audioRef.current) return;
        audioRef.current.volume = direction === "out" ? 0 : maxVolume;
      },
    });
  }

  useEffect(() => {
    fadeAudio("out");
  }, [intro1Finished]);

  useEffect(() => {
    fadeAudio("in");
  }, [intro2Finished]);

  useEffect(() => {
    if(currUseCase !== undefined && !showProducts) fadeAudio("out");
    else fadeAudio("in");
  }, [currUseCase, showProducts]);

  useEffect(() => {
    if(positionState?.step === "stationary_03done") {
      setShowIntro(false);
      setShowOverview(false);
    }
  }, [positionState]);

  const [musicOn, setMusicOn] = useState(true);
  useEffect(() => {
    if(!playIntro) return;
    (window as any).c = audioRef.current;
    if(musicOn) audioRef.current?.play();
    else audioRef.current?.pause();
  }, [musicOn, playIntro]);

  const fontSizeConnectedLiving = useResponsiveFontSize(15);
  const sizeHeader = useResponsiveSize(40);
  const fontSizeTonAus = useResponsiveFontSize(13);
  const sizeTonAus = useResponsiveSize(5);
  
  const changedViewpointRef = useUpdateSidebar({positionState, getAreaFromObjectName, sidebarState, updateSidebarRef, setSidebarState, setShowOverview});

  return (
    <ThemeProvider theme={theme}>
      <audio
        ref={audioRef}
        src={samsungLoop}
        style={{
          display: "none",
        }}
        loop
      />
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          onClick={() => {
            try {
              if(intro1VideoRef.current && audioRef.current) {
                intro1VideoRef.current.play();
                audioRef.current.volume = 0;
                audioRef.current.play();
                fadeAudio("in", 2);
                setPlayIntro(true);
              }
            } catch (err) {
              console.log("could not play media");
            }
          }}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Paper
            style={{
              padding: "0 0 0 16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              zIndex: 10,
              borderRadius: 0,
              borderBottom: (!intro1Finished || showOverview) ? "1px solid rgb(80, 80, 80)" : undefined,
            }}
          >
            <Typography
              style={{
                fontFamily: "SamsungSharpSans-Bold",
                fontSize: upSM ? fontSizeConnectedLiving : "10pt",
              }}
            >
              {!intro1Finished ? "Connected Living" : "Experience Studio for Connected Living"}
            </Typography>
            <TopMenu
              sizeHeader={sizeHeader}
              intro1Finished={intro1Finished}
              areas={database.areas}
              currArea={currArea}
              showOverview={showOverview}
              sidebarState={sidebarState}
              updateSidebar={updateSidebar}
              setSidebarState={setSidebarState}
              positionState={positionState}
              setPositionState={setPositionState}
              changedViewpointRef={changedViewpointRef}
              model={model}
              toggleOverview={toggleOverview}
            />
          </Paper>
          <div
            style={{
              flex: "1 0 0",
              position: "relative",
            }}
          >
            <IntroViewer
              showIntro={showIntro}
              setShowIntro={setShowIntro}
              playIntro={playIntro}
              intro1VideoRef={intro1VideoRef}
              intro1Finished={intro1Finished}
              setIntro1Finished={setIntro1Finished}
              intro2Finished={intro2Finished}
              setIntro2Finished={setIntro2Finished}
              sidebarWidth={sidebarWidth}
              setPositionState={setPositionState}
              startObject={model?.getObjectByName("05")}
              overviewObject={model?.getObjectByName("13")}
            />
            <UseCaseAreaViewer
              currUseCase={currUseCase}
              setCurrUseCase={setCurrUseCase}
              showProducts={showProducts}
              setShowProducts={setShowProducts}
            />
            {
              (sidebarState.state === "open") && currAreaId && currArea?.type === "interactive" ? (
                <InteractiveAreaViewer
                  areaId={currAreaId}
                  area={currArea}
                />
              ) : null
            }
            {
              showOverview ? (
                <Overview
                  areas={database.areas}
                  positionState={positionState}
                  setPositionState={setPositionState}
                  model={model}
                  setShowOverview={setShowOverview}
                  setSidebarState={setSidebarState}
                  toggleOverview={toggleOverview}
                  getAreaFromObjectName={getAreaFromObjectName}
                  sidebarState={sidebarState}
                  updateSidebar={updateSidebar}
                />
              ) : null
            }
            {
              start3d ? (
                <Canvas
                  invalidateFrameloop
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  touch-action="none"
                  onPointerMissed={() => {
                    moveToPointerDirectionFunc();
                  }}
                >
                  <GroundCursor
                    model={model}
                    setMoveToPointerDirectionFunc={setMoveToPointerDirectionFunc}
                    positionState={positionState}
                    setPositionState={setPositionState}
                    route={route}
                  />
                  <PhotosphereViewer
                    model={model}
                    setModel={setModel}
                    positionState={positionState}
                    setPositionState={setPositionState}
                    forceResizeRef={forceResizeRef}
                    invalidateRef={invalidateRef}
                    areas={database.areas}
                  />
                  <ViewpointMarkers
                    model={model}
                    areas={database.areas}
                    positionState={positionState}
                    setPositionState={setPositionState}
                    route={route}
                  />
                </Canvas>
              ) : null
            }
            {
              !showIntro ? (
                <Sidebar
                  ref={sidebarRef}
                  currUseCase={currUseCase}
                  sidebarState={sidebarState}
                  setCurrUseCase={setCurrUseCase}
                  setSidebarState={setSidebarState}
                  productInfos={database.productInfos}
                  showProducts={showProducts}
                  updateSidebar={updateSidebar}
                />
              ) : null
            }
            <div
              style={{
                position: "absolute",
                left: "1%",
                bottom: "3%",
                height: sizeTonAus+"%",
                display: (!showIntro && (!currUseCase || showProducts)) ? "flex" : "none",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
                userSelect: "none",
                zIndex: 8,
              }}
              onClick={() => setMusicOn(x => !x)}
            >
              <img
                src={musicOn ? musicOffIcon : musicOnIcon}
                style={{
                  height: "100%",
                }}
                alt="toggle sound"
              />
              <div
                style={{
                  margin: "0 8px",
                  fontFamily: "SamsungSharpSans-Bold",
                  color: "white",
                  paddingTop: "3px",
                  fontSize: upSM ? fontSizeTonAus : "10pt",
                  textShadow: "2px 2px rgb(100, 100, 100)",
                  whiteSpace: "nowrap",
                }}
              >
                {
                  musicOn ? "Ton aus" : "Ton an"
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
