import React from 'react';
import { ProductInfos, UseCase } from '../App.js';
// @ts-ignore
import productImageIndex from '../data/productImages/imageIndex.js';
// @ts-ignore
import deviceImageIndex from '../data/deviceImages/deviceImageIndex.js';

type ProductsSlideProps = {
  products: string[],
  highlightColor: string | undefined,
  productInfos: ProductInfos,
  currUseCase: UseCase,
};

export default function ProductsSlide({products, highlightColor, productInfos, currUseCase}: ProductsSlideProps) {
  return (
    <div
      style={{
        padding: "0 40px",
      }}
    >
      <div
        style={{
          width: "80%",
          paddingTop: 100/1024*80+"%",
          backgroundImage: `url(${deviceImageIndex.index[currUseCase.id].fileName})`,
          backgroundPosition: "left center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      />
      <div>
        <div
          style={{
            color: highlightColor,
            marginTop: "8px",
            fontFamily: "SamsungSharpSans-Medium",
            fontSize: "10pt",
          }}
        >
          Connected Living Package
        </div>
        <div
          style={{
            marginTop: "8px",
            fontFamily: "SamsungSharpSans-Medium",
            fontSize: "10pt",
          }}
        >
          Erstelle das Szenario mit diesen Geräten:
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          {
            products.map((productId, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  className="productImage"
                  style={{
                    backgroundImage: `url(${productImageIndex.index[productId]?.fileName || productImageIndex.index["missingImage"].fileName})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "170px",
                    height: "150px",
                    paddingBottom: "10%",
                    borderBottom: "solid 1px rgb(80, 80, 80)",
                  }}
                >
                  <a
                    href={productInfos[productId]?.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      pointerEvents: "all",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </a>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}
