import React, { useEffect, useRef, useMemo, createRef, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useThree, useLoader } from 'react-three-fiber';
import { animate, MotionValue } from 'framer-motion';
import Controls from './Controls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Suspense } from 'react';
// @ts-ignore
import fbxFile from "../data/scene.fbx";
// @ts-ignore
import photosphereIndex_ from '../data/photosphereImages/photosphereImageIndex.js';

import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader';
import Photosphere from './Photosphere';
import { Area } from '../App';

const photosphereIndex: { 
  dimensions: {
    width: number,
    height: number,
    subdivisionsH: number,
    subdivisionsV: number,
  },
  index: {[key: string]: string[][]}
} = photosphereIndex_;

function useWorldTF(object: THREE.Object3D | undefined) : [THREE.Vector3 | undefined, THREE.Quaternion | undefined] {
  return useMemo(() => {
    if(!object) return [undefined, undefined];
    const pos = new THREE.Vector3();
    const quat = new THREE.Quaternion();
    object.updateMatrixWorld();
    object.getWorldPosition(pos);
    object.getWorldQuaternion(quat);
    return [pos, quat];
  }, [object]);
}

export { useWorldTF };

type ModelProps = {
  setModel: React.Dispatch<React.SetStateAction<THREE.Group | undefined>>
}
export function Model({setModel}: ModelProps) {
  const { scene } = useLoader(GLTFLoader, fbxFile);

  useEffect(() => {
    setModel(scene);
  }, [setModel, scene]);
  return null;
}

type PositionStateStationary_StepStart = {
  step: "stationary_01start",
  object: THREE.Object3D,
};

type PositionStateStationary_StepLoad = {
  step: "stationary_02load",
  object: THREE.Object3D,
};

type PositionStateStationary_StepDone = {
  step: "stationary_03done",
  object: THREE.Object3D,
  textures: THREE.CompressedTexture[][],
};


type PositionStateMoving_StepStart = {
  step: "moving_01start",
  objects: THREE.Object3D[],
  orientCamAlongPath: boolean,
};

type PositionStateMoving_StepLoad = {
  step: "moving_02load",
  objects: THREE.Object3D[],
  orientCamAlongPath: boolean,
  startCamAngle1?: number,
  startCamAngle2?: number,
};

type PositionStateMoving_StepTransitioning = {
  step: "moving_03transitioning",
  objects: THREE.Object3D[],
  motionValue: MotionValue<number>,
  spline: THREE.CatmullRomCurve3,
  photospheres: {
    [key: string]: {
      ref: React.MutableRefObject<THREE.Group | null>,
      textures: THREE.CompressedTexture[][],
    }
  },
  duration: number,
  orientCamAlongPath: boolean,
  startCamAngle1?: number,
  startCamAngle2?: number,
};

type PositionStateMoving_StepOrienting = {
  step: "moving_04orienting",
  object: THREE.Object3D,
  textures: THREE.CompressedTexture[][],
  motionValue: MotionValue<number>,
  duration: number,
  startAngle1: number,
  angle1: number,
  startAngle2: number,
  angle2: number,
  startFov: number,
  fov: number,
};

export type PositionState =
  PositionStateStationary_StepStart | PositionStateStationary_StepLoad | PositionStateStationary_StepDone |
  PositionStateMoving_StepStart | PositionStateMoving_StepLoad | PositionStateMoving_StepTransitioning | PositionStateMoving_StepOrienting;

const getMotionInfo = (positionState: PositionStateMoving_StepTransitioning) => {
  const numPoints = positionState.objects.length;
  // @ts-ignore
  const percentTravelled = positionState.spline.getUtoTmapping(positionState.motionValue.get() / positionState.spline.getLength());
  const mainPhotosphereIndex = Math.floor(percentTravelled * (numPoints - 1));
  const nextPhotosphereIndex = mainPhotosphereIndex + 1;
  const mainNextPhotospherePct = (percentTravelled * (numPoints - 1)) % 1;

  return {
    mainPhotosphereIndex,
    nextPhotosphereIndex,
    mainNextPhotospherePct,
  };
};

const normalizeAngle = (angle: number) => {
  if(angle > Math.PI) angle -= 2*Math.PI;
  if(angle < -Math.PI) angle += 2*Math.PI;
  return angle;
};

type PhotosphereViewerProps = {
  positionState: PositionState | undefined,
  setPositionState: React.Dispatch<React.SetStateAction<PositionState | undefined>>,
  model: THREE.Group | undefined,
  setModel: React.Dispatch<React.SetStateAction<THREE.Group | undefined>>,
  forceResizeRef: React.MutableRefObject<(() => void) | undefined>,
  invalidateRef: React.MutableRefObject<(() => void) | undefined>,
  areas: {[key: string]: Area},
};
export default function PhotosphereViewer({positionState, setPositionState, model, setModel, forceResizeRef, invalidateRef, areas}: PhotosphereViewerProps) {
  const { gl, invalidate, forceResize, camera } = useThree();
  forceResizeRef.current = forceResize;
  invalidateRef.current = invalidate;

  // Pre-caching der Photospheres dauert 05:45 auf Fast 3G im Best Case Szenario
  useEffect(() => {
    let photospheresToPreload: string[] = [];
    for(let photosphere of Object.values(photosphereIndex.index)) {
      for(let i = 0; i < photosphereIndex.dimensions.subdivisionsH; i++) {
        for(let j = 0; j < photosphereIndex.dimensions.subdivisionsV; j++) {
          photospheresToPreload.push(photosphere[i][j]);
        }
      }
    }

    const preloadNext = async () => {
      await fetch(photospheresToPreload[0]);
      photospheresToPreload.shift();
    };

    (async () => {
      while(true) {
        if(photospheresToPreload.length === 0) {
          break;
        } else {
          await new Promise<void>((resolve, reject) => {
            setTimeout(() => {
              preloadNext().then(() => {
                resolve();
              });
            }, 500);
          });
        }
      }
    })();
  }, []);

  const controlsRef = useRef<THREE.Object3D>();

  const basisLoader = useMemo(() => {
    const basisLoader = new BasisTextureLoader();
    basisLoader.setTranscoderPath('./basis/');
    basisLoader.detectSupport(gl);
    return basisLoader;
  }, [gl]);

  useEffect(() => {
    if(positionState && positionState.step === "stationary_01start") {
      setPositionState({
        step: "stationary_02load",
        object: positionState.object,
      });
    }
  }, [positionState, setPositionState]);

  useEffect(() => {
    if(positionState && positionState.step === "moving_01start") {
      setPositionState({
        step: "moving_02load",
        objects: positionState.objects,
        orientCamAlongPath: positionState.orientCamAlongPath,
        startCamAngle1: controlsRef.current?.rotation.y,
        startCamAngle2: controlsRef.current?.children[0].rotation.x,
      });
    }
  }, [positionState, setPositionState]);

  const loadTexturesForPhotosphere = useMemo(() => (name: string) => new Promise<THREE.CompressedTexture[][]>((resolve, reject) => {
    let textures: THREE.CompressedTexture[][] = [];
    const promises = [];
    for(let i = 0; i < photosphereIndex.dimensions.subdivisionsH; i++) {
      for(let j = 0; j < photosphereIndex.dimensions.subdivisionsV; j++) {
        if(textures[i] === undefined) textures[i] = [];
        promises.push(new Promise((resolve, reject) => {
          setTimeout(() => {
            basisLoader.load(photosphereIndex.index[name][i][j], texture => {
              texture.encoding = THREE.sRGBEncoding;
              textures[i][j] = texture;
              gl.initTexture(texture);
              resolve();
            });
          }, 0);
        }));
      }
    }

    Promise.all(promises).then(() => {
      resolve(textures);
    });
  }), [basisLoader, gl]);

  useEffect(() => {
    if(positionState?.step === "stationary_02load") {
      loadTexturesForPhotosphere(positionState.object.name).then(textures => {
        if(positionState.step !== "stationary_02load") return;
        const area = Object.values(areas).find(a => a.viewpointObject === positionState.object.name);
        if(controlsRef.current && area) {
          controlsRef.current.rotation.y = area.cameraAngle1;
          controlsRef.current.children[0].rotation.x = area.cameraAngle2;
          (camera as THREE.PerspectiveCamera).fov = area.cameraFov;
          (camera as THREE.PerspectiveCamera).updateProjectionMatrix();
        }
        requestAnimationFrame(() => {
          setPositionState({
            step: "stationary_03done",
            object: positionState.object,
            textures,
          });
        });
      });
    }
  }, [loadTexturesForPhotosphere, positionState, setPositionState, areas, camera]);

  useEffect(() => {
    if(positionState?.step === "moving_02load") {
      const promises = [];
      const allTextures: {
        [key: string]: THREE.CompressedTexture[][]
       } = {};
      for(let object of positionState.objects) {
        promises.push(new Promise((resolve, reject) => {
          loadTexturesForPhotosphere(object.name).then(textures => {
            if(positionState.step !== "moving_02load") return;
            allTextures[object.name] = textures;
            resolve();
          });
        }));
      }
      Promise.all(promises).then(() => {
        if(positionState.step !== "moving_02load") return;

        const spline = new THREE.CatmullRomCurve3(positionState.objects.map(o => o.getWorldPosition(new THREE.Vector3())));

        const mv = new MotionValue(0);
        const duration = Math.max(positionState.orientCamAlongPath ? 2 : 1, spline.getLength() / (positionState.orientCamAlongPath ? 0.75 : 1.5));
        animate(mv, spline.getLength(), {
          type: "tween",
          duration,
          onUpdate: () => {
            invalidate();
          },
          onComplete: () => {
            invalidate();
          },
        });
        const photospheres: {
          [key: string]: {
            ref: React.MutableRefObject<THREE.Group | null>,
            textures: THREE.CompressedTexture[][],
          }
        } = {};
        for(let o of positionState.objects) {
          photospheres[o.name] = {
            ref: createRef(),
            textures: allTextures[o.name],
          }
        }
        setPositionState({
          step: "moving_03transitioning",
          objects: positionState.objects,
          motionValue: mv,
          spline,
          photospheres,
          duration,
          orientCamAlongPath: positionState.orientCamAlongPath,
          startCamAngle1: positionState.startCamAngle1,
          startCamAngle2: positionState.startCamAngle2,
        });
      });
    }
  }, [loadTexturesForPhotosphere, positionState, setPositionState, invalidate]);

  useFrame(() => {
    if(!mainSphereRef.current) return;
    if(positionState?.step === "stationary_03done") {
      mainSphereRef.current.position.copy(positionState.object.getWorldPosition(new THREE.Vector3()));
      mainSphereRef.current.quaternion.copy(positionState.object.getWorldQuaternion(new THREE.Quaternion()));
    }
  });

  useFrame(() => {
    if(!controlsRef.current) return;
    if(positionState?.step === "stationary_03done") {
      controlsRef.current.position.copy(positionState.object.getWorldPosition(new THREE.Vector3()));
    }
    if(positionState?.step === "moving_03transitioning") {
      const m = positionState.motionValue.get();
      const l = positionState.spline.getLength();
      const at = l > 0 ? m / l : 0;
      const currPos = l > 0 ? positionState.spline.getPointAt(at) : positionState.objects[positionState.objects.length-1].getWorldPosition(new THREE.Vector3());
      controlsRef.current.position.copy(currPos);
      if(positionState.orientCamAlongPath && l > 0) {
        const tangent = positionState.spline.getTangentAt(at);
        const dir = new THREE.Vector3(0, 0, 1);
        const up = new THREE.Vector3(0, 1, 0);
        let angleAlongPath = tangent.angleTo(dir) + Math.PI;
        const cross = dir.clone().cross(tangent);
        if(up.dot(cross) < 0) angleAlongPath *= -1;
        angleAlongPath = normalizeAngle(angleAlongPath);
        if(positionState.startCamAngle1 !== undefined && positionState.startCamAngle2 !== undefined) {
          const delta1 = normalizeAngle(angleAlongPath - positionState.startCamAngle1);
          controlsRef.current.rotation.y = positionState.startCamAngle1 + Math.min(2*m, 1) * delta1;
          const delta2 = normalizeAngle(0 - positionState.startCamAngle2);
          controlsRef.current.children[0].rotation.x = positionState.startCamAngle2 + Math.min(2*m, 1) * delta2;
        }
      }
    }
  });

  useFrame(() => {
    if(positionState?.step === "moving_03transitioning") {
      const {
        mainPhotosphereIndex,
        nextPhotosphereIndex,
        mainNextPhotospherePct,
      } = getMotionInfo(positionState);

      if(positionState.objects[mainPhotosphereIndex]) {
        const mainPhotosphere = positionState.photospheres[positionState.objects[mainPhotosphereIndex].name].ref.current;
        if(mainPhotosphere) {
          mainPhotosphere.scale.set(1, 1, 1);
          mainPhotosphere.traverse(o => {
            if((o as any).material) {
              const mat = (o as any).material as THREE.MeshBasicMaterial;
              mat.opacity = 1-mainNextPhotospherePct;
              mat.transparent = true;
              o.visible = true;
            }
          });
        }
      }
      if(positionState.objects[nextPhotosphereIndex]) {
        const nextPhotosphere = positionState.photospheres[positionState.objects[nextPhotosphereIndex].name].ref.current;
        if(nextPhotosphere) {
          nextPhotosphere.scale.set(2, 2, 2);
          nextPhotosphere.traverse(o => {
            if((o as any).material) {
              const mat = (o as any).material as THREE.MeshBasicMaterial;
              mat.opacity = 1;
              mat.transparent = false;
              o.visible = true;
            }
          });
        }
      }
      const otherPhotospheres = positionState.objects.filter((o, i) => !(i === mainPhotosphereIndex || i === nextPhotosphereIndex)).map(o => positionState.photospheres[o.name].ref.current as THREE.Group);
      for(let p of otherPhotospheres) {
        p.traverse(o => {
          p.scale.set(1, 1, 1);
          if((o as any).material) {
            const mat = (o as any).material as THREE.MeshBasicMaterial;
            mat.opacity = 0;
            mat.transparent = false;
            o.visible = false;
          }
        })
      }
    }
  });

  useFrame(() => {
    if(positionState?.step === "moving_03transitioning" && !positionState.motionValue.isAnimating()) {
      const finalObject = positionState.objects.slice(-1)[0];
      const area = Object.values(areas).find(a => a.viewpointObject === finalObject.name);
      if(area) {
        const mv = new MotionValue(0);
        const delta1 = normalizeAngle(area.cameraAngle1 - (controlsRef.current ? controlsRef.current.rotation.y : 0));
        const duration = Math.max(1, Math.abs(delta1) * 0.75);
        animate(mv, 1, {
          type: "tween",
          duration,
          onUpdate: () => {
            invalidate();
          },
          onComplete: () => {
            invalidate();
          },
        });
        setPositionState({
          step: "moving_04orienting",
          object: finalObject,
          textures: positionState.photospheres[finalObject.name].textures,
          motionValue: mv,
          duration,
          startAngle1: controlsRef.current ? controlsRef.current.rotation.y : 0,
          angle1: area.cameraAngle1,
          startAngle2: controlsRef.current ? controlsRef.current.children[0].rotation.x : 0,
          angle2: area.cameraAngle2,
          startFov: (camera as THREE.PerspectiveCamera).fov,
          fov: area.cameraFov,
        });
      } else {
        setPositionState({
          step: "stationary_03done",
          object: finalObject,
          textures: positionState.photospheres[finalObject.name].textures,
        });
      }
    }
  });

  useFrame(() => {
    if(positionState?.step === "moving_04orienting" && positionState.motionValue.isAnimating()) {
      if(!controlsRef.current) return;
      const delta1 = normalizeAngle(positionState.angle1 - positionState.startAngle1);
      const delta2 = positionState.angle2 - positionState.startAngle2;
      const deltaFov = positionState.fov - positionState.startFov;
      controlsRef.current.rotation.y = positionState.startAngle1 + delta1 * positionState.motionValue.get();
      controlsRef.current.children[0].rotation.x = positionState.startAngle2 + delta2 * positionState.motionValue.get();
      (camera as THREE.PerspectiveCamera).fov = positionState.startFov + deltaFov * positionState.motionValue.get();
      (camera as THREE.PerspectiveCamera).updateProjectionMatrix();
    }
  });

  useFrame(() => {
    if(positionState?.step === "moving_04orienting" && !positionState.motionValue.isAnimating()) {
      setPositionState({
        step: "stationary_03done",
        object: positionState.object,
        textures: positionState.textures,
      });
    }
  });

  const mainSphereRef = useRef<THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>>();
  
  const sceneRef = useRef<THREE.Scene>();
  
  useFrame(state => {
    state.gl.autoClear = false;
  }, 0);

  useFrame(state => {
    if(!sceneRef.current) return;
    state.gl.render(sceneRef.current, state.camera);
    state.gl.clearDepth();
  }, 1);

  const [mainPhotosphereConfig, setMainPhotosphereConfig] = useState<{
    textures: THREE.CompressedTexture[][],
    position: THREE.Vector3,
    quaternion: THREE.Quaternion,
  }>();
  useEffect(() => {
    if(positionState?.step === "stationary_03done") {
      setMainPhotosphereConfig({
        position: positionState.object.getWorldPosition(new THREE.Vector3()),
        quaternion: positionState.object.getWorldQuaternion(new THREE.Quaternion()),
        textures: positionState.textures,
      });
    }
    if(positionState?.step === "moving_03transitioning") {
      setTimeout(() => {
        const lastObject = positionState.objects[positionState.objects.length-1];
        setMainPhotosphereConfig({
          position: lastObject.getWorldPosition(new THREE.Vector3()),
          quaternion: lastObject.getWorldQuaternion(new THREE.Quaternion()),
          textures: positionState.photospheres[lastObject.name].textures,
        });
      }, positionState.duration / 2 * 1000);
    }
  }, [positionState]);

  const mainSize = 5;

  return (
    <>
      <Suspense fallback={null}>
        <Model
          setModel={setModel}
        />
      </Suspense>
      <scene
        ref={sceneRef}
      >
        <Controls
          ref={controlsRef}
          rotDamping={0.9}
          rotSensitivity={0.35}
          zoomDamping={0.9}
          zoomSensitivity={1.5}
          enabled={positionState?.step !== "moving_04orienting"}
        />
        <Photosphere
          ref={mainSphereRef}
          textures={mainPhotosphereConfig?.textures}
          dimensions={photosphereIndex.dimensions}
          radius={mainSize*2*2}
          position={mainPhotosphereConfig?.position}
          quaternion={mainPhotosphereConfig?.quaternion}
          transparent={false}
        />
        {
          positionState?.step === "moving_03transitioning" ? (
            positionState.objects.map((o, i) => (
              <Photosphere
                key={i}
                ref={positionState.photospheres[o.name].ref}
                textures={positionState.photospheres[o.name].textures}
                dimensions={photosphereIndex.dimensions}
                radius={mainSize}
                position={o.getWorldPosition(new THREE.Vector3())}
                quaternion={o.getWorldQuaternion(new THREE.Quaternion())}
              />
            ))
          ) : null
        }
      </scene>
    </>
  );
}
