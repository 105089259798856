import React, { useEffect, useRef } from "react";
import { Paper, useMediaQuery, useTheme } from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import startButton from './data/images/startButton.png';
// @ts-ignore
import videoIndex from './data/videos/videoIndex.js';
import { PositionState } from "./PhotosphereViewer/PhotosphereViewer";
import anflugImage from './data/images/anflug.jpg';
import { useResponsiveFontSize, useResponsiveSize } from "./useResponsiveFontSize";

type IntroViewerProps = {
  showIntro: boolean,
  setShowIntro: React.Dispatch<React.SetStateAction<boolean>>,
  playIntro: boolean,
  intro1VideoRef: React.RefObject<HTMLVideoElement>,
  intro1Finished: boolean,
  setIntro1Finished: React.Dispatch<React.SetStateAction<boolean>>,
  intro2Finished: boolean,
  setIntro2Finished: React.Dispatch<React.SetStateAction<boolean>>,
  sidebarWidth: number,
  setPositionState: (value: React.SetStateAction<PositionState | undefined>) => void,
  startObject: THREE.Object3D | undefined,
  overviewObject: THREE.Object3D | undefined,
};
export default function IntroViewer({showIntro, setShowIntro, playIntro, intro1VideoRef, intro1Finished, setIntro1Finished, intro2Finished, setIntro2Finished, sidebarWidth, setPositionState, startObject, overviewObject}: IntroViewerProps) {
  const intro2VideoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if(!intro1VideoRef.current) return;
    if(!playIntro) {
      try {
        intro1VideoRef.current.pause();
        intro1VideoRef.current.currentTime = 0;
      } catch(err) {
        console.log("could not pause");
      }
    }
  }, [playIntro, intro1VideoRef]);
  useEffect(() => {
    if(intro1Finished && intro2VideoRef.current) {
      try {
        intro2VideoRef.current.play();
      } catch(err) {
        console.log("could not play video");
      }
    }
  }, [intro1Finished]);

  const fontSizeExperienceStudioForConnectedLiving = useResponsiveFontSize(64);
  const fontSizeStartYourJourney = useResponsiveFontSize(17);
  const sizeStartMenu = useResponsiveSize(1);
  
  const theme = useTheme();
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  
  return (
    showIntro ? (
      <Paper
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center-start",
          zIndex: 3,
          backgroundColor: "black",
          borderRadius: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            paddingTop: 692/1440*100+"%",
          }}
        >
        <video
          ref={intro2VideoRef}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            display: playIntro ? "block" : "none",
            backgroundColor: "black",
          }}
          src={videoIndex.index["intro"].fileName}
          onEnded={() => setIntro2Finished(true)}
          controls
        />
        <video
          ref={intro1VideoRef}
          poster={anflugImage}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            display: intro1Finished ? "none" : "block",
            backgroundColor: "black",
          }}
          src={videoIndex.index["anflug"].fileName}
          onEnded={() => setIntro1Finished(true)}
        />
        {
          !playIntro ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: "4.5 0 0",
                }}
              />
              <div
                style={{
                  flex: "2 0 0",
                  fontFamily: "SamsungSharpSans-Bold",
                  fontSize: fontSizeExperienceStudioForConnectedLiving,
                  textAlign: "center",
                  padding: "0 15%",
                }}
              >
                Experience Studio<br />
                for Connected Living
              </div>
              <div
                style={{
                  flex: "2 0 0",
                }}
              />
              <div
                style={{
                  flex: "2 0 0",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    flex: "3.5 0 0",
                    height: "100%",
                  }}
                />
                <div
                  style={{
                    flex: "3 0 0",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "SamsungSharpSans-Bold",
                    fontSize: "24pt",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      flex: "1 0 0",
                      backgroundImage: `url(${startButton})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right center",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                  <div
                    style={{
                      flex: "5 0 0",
                      fontSize: fontSizeStartYourJourney,
                    }}
                  >
                    Start your journey
                  </div>
                </div>
                <div
                  style={{
                    flex: "1 0 0"
                  }}
                />
              </div>
              <div
                style={{
                  flex: "2 0 0",
                }}
              />
            </div>
          ) : null
        }
        {
          intro2Finished ? (
              <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                height: "100%",
                width: upSM ? sidebarWidth + "%" : undefined,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  fontFamily: "SamsungSharpSans-Bold",
                  padding: upSM ? "16px 0" : undefined,
                  height: upSM ? undefined : "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "32px",
                    cursor: "pointer",
                    transform: `scale(${sizeStartMenu})`,
                    transformOrigin: "center left",
                    width: "100%",
                  }}
                  onClick={() => {
                    if(startObject) {
                      setPositionState({
                        step: "stationary_01start",
                        object: startObject,
                      });
                    }
                    setShowIntro(false);
                  }}
                >
                  <ArrowForwardIcon
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                    fontSize="small"
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Tour starten
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "32px",
                    cursor: "pointer",
                    transform: `scale(${sizeStartMenu})`,
                    transformOrigin: "center left",
                    width: "100%",
                  }}
                  onClick={() => {
                    if(overviewObject) {
                      setPositionState({
                        step: "moving_01start",
                        objects: [
                          overviewObject,
                          overviewObject,
                        ],
                        orientCamAlongPath: false,
                      });
                    }
                    setShowIntro(false);
                  }}
                >
                  <ArrowForwardIcon
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                    fontSize="small"
                  />
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    Orientierung verschaffen
                  </div>
                </div>
              </div>
              <div
                style={{
                  flex: "4 0 0",
                }}
              />
            </div>
          ) : null
        }
        </div>
      </Paper>
    ) : null
  );
}