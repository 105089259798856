import React, { useRef } from "react";
import { Area_any, InteractiveArea, sidebarStateType, UseCaseArea } from "./App";
import { convertRGB255ToCss } from "./colorHelpers";
import overviewImage from './data/images/overview.jpg';
import { PositionState } from "./PhotosphereViewer/PhotosphereViewer";
import CancelIcon from '@material-ui/icons/Cancel';
import { MotionValue } from "framer-motion";
import { useUpdateSidebar } from "./useUpdateSidebar";
import { useResponsiveFontSize, useResponsiveSize } from "./useResponsiveFontSize";
import { useMediaQuery, useTheme } from "@material-ui/core";

type OverviewProps = {
  areas: {
    [key: string]: Area_any,
  },
  positionState: PositionState | undefined,
  setPositionState: (value: React.SetStateAction<PositionState | undefined>) => void,
  model: THREE.Group | undefined,
  setShowOverview: React.Dispatch<React.SetStateAction<boolean>>,
  setSidebarState: React.Dispatch<React.SetStateAction<sidebarStateType>>,
  toggleOverview: () => void,
  getAreaFromObjectName: (name: string) => UseCaseArea | InteractiveArea | undefined,
  sidebarState: sidebarStateType,
  updateSidebar: (state: "opening" | "closing" | "open" | "close", motionValue?: MotionValue<any> | undefined) => void,
};

export default function Overview({areas, positionState, setPositionState, model, setShowOverview, setSidebarState, toggleOverview, getAreaFromObjectName, sidebarState, updateSidebar}: OverviewProps) {
  const sphereRadius = 0.9;

  const updateSidebarRef = useRef(updateSidebar);
  const changedViewpointRef = useUpdateSidebar({positionState, getAreaFromObjectName, sidebarState, updateSidebarRef, setSidebarState, setShowOverview});

  const zurueckFontSize = useResponsiveFontSize(13);
  const iconScale = useResponsiveSize(1.33);

  const theme = useTheme();
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 8,
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          paddingTop: 692/1440*100 + "%",
          position: "relative",
          backgroundImage: `url(${overviewImage})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            position: "absolute",
            right: "1%",
            bottom: "3%",
            color: "white",
            fontFamily: "SamsungSharpSans-Bold",
            fontSize: upSM ? zurueckFontSize : "10pt",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={toggleOverview}
        >
          <div>
            zurück zur Tour
          </div>
          <CancelIcon
            style={{
              backgroundColor: "black",
              borderRadius: "50%",
              borderWidth: "10px",
              marginLeft: "7px",
              transform: `scale(${upSM ? iconScale : 1})`,
            }}
            fontSize="small"
          />
        </div>
        {
          Object.keys(areas).map(areaId => (
            <div
              key={areaId}
              style={{
                position: "absolute",
                left: `calc(${areas[areaId].mapPosition.x}% - ${sphereRadius}%)`,
                top: `calc(${areas[areaId].mapPosition.y}% - ${sphereRadius}%)`,
                width: `${sphereRadius*2}%`,
                paddingTop: `${sphereRadius*2}%`,
                borderRadius: "50%",
                border: "solid 2px white",
                backgroundColor: convertRGB255ToCss(areas[areaId].color),
                cursor: "pointer",
              }}
              onClick={() => {
                changedViewpointRef.current = true;
                const targetObject = model?.getObjectByName(areas[areaId].viewpointObject) as THREE.Object3D;
                setPositionState({
                  step: "stationary_01start",
                  object: targetObject,
                });
              }}
            />
          ))
        }
      </div>
    </div>
  );
}