import { Sphere } from '@react-three/drei';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import mergeRefs from 'react-merge-refs';

type PhotosphereProps = {
  textures: THREE.CompressedTexture[][] | undefined,
  dimensions: {
    width: number,
    height: number,
    subdivisionsH: number,
    subdivisionsV: number,
  },
  radius: number,
  transparent?: boolean,
  position?: THREE.Vector3,
  visible?: boolean | undefined,
  quaternion?: THREE.Quaternion,
};
const Photosphere = React.forwardRef(({textures, dimensions, radius, transparent, position, visible, quaternion} : PhotosphereProps, ref) => {
  const containerRef = useRef<THREE.Group>();

  useEffect(() => {
    if(!textures) return;
    for(let i = 0; i < dimensions.subdivisionsH; i++) {
      for(let j = 0; j < dimensions.subdivisionsV; j++) {
        if(containerRef.current) {
          if(!containerRef.current.children[i].children[j].hasOwnProperty("material")) return;
          const o = containerRef.current.children[i].children[j] as THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>;
          o.material.needsUpdate = true;
        }
      }
    }
  }, [dimensions, textures]);

  return (
    <group
      ref={mergeRefs([ref, containerRef])}
      position={position}
      quaternion={quaternion}
      visible={visible}
    >
      {
        Array(dimensions.subdivisionsH).fill(0).map((a, i) => (
          <group
            key={i}
          >
            {
              Array(dimensions.subdivisionsV).fill(0).map((b, j) => (
                <Sphere
                  key={j}
                  args={[radius, 128, 128, i*2*Math.PI/dimensions.subdivisionsH, 2*Math.PI/dimensions.subdivisionsH, j*Math.PI/dimensions.subdivisionsV, Math.PI/dimensions.subdivisionsV]}
                  scale={[-1, 1, 1]}
                >
                  <meshBasicMaterial
                    side={THREE.BackSide}
                    transparent={transparent}
                    map={textures ? textures[i][j] : undefined}
                    toneMapped={false}
                  />
                </Sphere>
              ))
            }
          </group>
        ))
      }
    </group>
  );
});

export default Photosphere;
