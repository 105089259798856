import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { InteractiveArea } from "./App.js";
// @ts-ignore
import videoIndex from './data/videos/videoIndex.js';

type InteractiveAreaViewerProps = {
  areaId: string,
  area: InteractiveArea,
};

export default function InteractiveAreaViewer({area, areaId}: InteractiveAreaViewerProps) {
  const initialPlayRef = useRef(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const [currState, setCurrState] = useState("");
  const handleUpdate = useMemo(() => (currState: string) => {
    if(!containerRef.current) return;
    for(let child of Array.from(containerRef.current.children)) {
      if(child.tagName.toLowerCase() === "video") {
        const videoChild = child as HTMLVideoElement;
        if(videoChild.id === "video") {
          if(!initialPlayRef.current) {
            videoChild.currentTime = 0;
            videoChild.pause();
          } else {
            videoChild.style.opacity = "1";
            videoChild.play();
            initialPlayRef.current = false;
          }
        } else {
          if(videoChild.id === "video"+currState) {
            videoChild.currentTime = 0;
            videoChild.play();
          } else {
            videoChild.pause();
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    handleUpdate("");
  }, [handleUpdate]);
  
  return (
    <>
      <div
        ref={containerRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}
      >
        {
          Object.keys(area.states).map(stateId => (
            <video
              key={"video"+stateId}
              id={"video"+stateId}
              style={{
                opacity: currState === stateId ? 1 : 0,
                transition: "all 0.5s ease-in-out",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                zIndex: 4,
              }}
              src={videoIndex.index[areaId+"_"+stateId].fileName}
              onEnded={() => {
                handleUpdate("");
                setCurrState("");
              }}
            />
          ))
        }
        <video
          id="video"
          style={{
            opacity: 0,
            transition: "all 0.5s ease-in-out",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            zIndex: 3,
          }}
          src={videoIndex.index[areaId].fileName}
          loop
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            paddingTop: 692/1440*100 + "%",
            position: "relative",
          }}
        >
          {
            Object.keys(area.states).map(stateId => (
              area.states[stateId].buttons.map((button, i) => (
                <div
                  key={"button"+stateId+i}
                  style={{
                    position: "absolute",
                    left: button.left + "%",
                    bottom: button.bottom + "%",
                    width: button.width + "%",
                    height: button.height + "%",
                    cursor: currState !== stateId ? "pointer" : undefined,
                    pointerEvents: "all",
                  }}
                  onClick={() => {
                    if(currState === stateId) return;
                    handleUpdate(stateId);
                    setCurrState(stateId);
                  }}
                />
              ))
            ))
          }
        </div>
      </div>
    </>
  );
}