import { MotionValue, animate } from "framer-motion";
import { useRef, useEffect } from "react";
import { UseCaseArea, InteractiveArea, sidebarStateType } from "./App";
import { PositionState } from "./PhotosphereViewer/PhotosphereViewer";

type useUpdateSidebarProps = {
  positionState: PositionState | undefined,
  getAreaFromObjectName: (name: string) => UseCaseArea | InteractiveArea | undefined,
  sidebarState: sidebarStateType,
  updateSidebarRef: React.MutableRefObject<(state: "opening" | "closing" | "open" | "close", motionValue?: MotionValue<any> | undefined) => void>,
  setSidebarState: React.Dispatch<React.SetStateAction<sidebarStateType>>,
  setShowOverview: React.Dispatch<React.SetStateAction<boolean>>,
};

export const useUpdateSidebar = ({positionState, getAreaFromObjectName, sidebarState, updateSidebarRef, setSidebarState, setShowOverview}: useUpdateSidebarProps) => {
  const changedViewpointRef = useRef(false);
  useEffect(() => {
    if(positionState?.step === "stationary_03done" && changedViewpointRef.current) {
      const newArea = getAreaFromObjectName(positionState.object.name);
      if(newArea !== undefined && newArea !== sidebarState.area) {
        if(sidebarState.state !== "open" && sidebarState.state !== "opening") {
          const mv = new MotionValue(0);
          animate(mv, 1, {
            type: "tween",
            duration: 500 / 1000,
            onUpdate: () => {
              updateSidebarRef.current("opening", mv);
            },
            onComplete: () => {
              setSidebarState({
                state: "open",
                area: newArea,
              });
              updateSidebarRef.current("open");
            },
          });
          setSidebarState({
            state: "opening",
            motionValue: mv,
            area: newArea,
          });
        } else {
          setSidebarState({
            state: "open",
            area: newArea,
          });
        }
      }
      setShowOverview(false);
      changedViewpointRef.current = false;
    }
  }, [setShowOverview, positionState, getAreaFromObjectName, setSidebarState, sidebarState, updateSidebarRef]);
  return changedViewpointRef;
};
