import { Button, useMediaQuery, useTheme, Menu, MenuItem } from '@material-ui/core';
import { MotionValue, animate } from 'framer-motion';
import React from 'react';
import { Area_any, InteractiveArea, sidebarStateType, UseCaseArea } from './App';
import { convertRGB255ToCss } from './colorHelpers';
import { PositionState } from './PhotosphereViewer/PhotosphereViewer';
import { useResponsiveFontSize, useResponsiveSize } from './useResponsiveFontSize';

type TopMenuProps = {
  sizeHeader: number,
  intro1Finished: boolean,
  areas: {
    [key: string]: Area_any;
  },
  currArea: InteractiveArea | UseCaseArea | null | undefined,
  showOverview: boolean,
  sidebarState: sidebarStateType,
  updateSidebar: (state: "opening" | "closing" | "open" | "close", motionValue?: MotionValue<any> | undefined) => void,
  setSidebarState: (value: React.SetStateAction<sidebarStateType>) => void,
  positionState: PositionState | undefined,
  setPositionState: React.Dispatch<React.SetStateAction<PositionState | undefined>>,
  changedViewpointRef: React.MutableRefObject<boolean>,
  model: THREE.Group | undefined,
  toggleOverview: () => void,
};

export default function TopMenu({sizeHeader, intro1Finished, areas, currArea, showOverview, sidebarState, updateSidebar, setSidebarState, positionState, setPositionState, changedViewpointRef, model, toggleOverview}: TopMenuProps) {
  const topMenuColorBarHeight = useResponsiveSize(3);
  const fontSizeHeaderButtons = useResponsiveFontSize(11);
  const theme = useTheme();
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuItemClick = (item: string) => {
    setAnchorEl(null);
    if(sidebarState?.state === "close" && sidebarState.area === areas[item]) {
      const mv = new MotionValue(0);
      animate(mv, 1, {
        type: "tween",
        duration: 500 / 1000,
        onUpdate: () => {
          updateSidebar("opening", mv);
        },
        onComplete: () => {
          setSidebarState({
            state: "open",
            area: sidebarState?.area as Area_any,
          });
          updateSidebar("open");
        },
      });
      setSidebarState({
        state: "opening",
        motionValue: mv,
        area: sidebarState.area,
      });
    } else {
      const endPoint = areas[item].viewpointObject;
      if(positionState?.step !== "stationary_03done") {
        setPositionState({
          step: "stationary_01start",
          object: model?.getObjectByName(endPoint) as THREE.Object3D
        });
        const mv = new MotionValue(0);
        animate(mv, 1, {
          type: "tween",
          duration: 1,
          onUpdate: () => {
            updateSidebar("opening", mv);
          },
          onComplete: () => {
            setSidebarState({
              state: "open",
              area: areas[item],
            });
            updateSidebar("open");
          },
        });
        setSidebarState({
          state: "opening",
          motionValue: mv,
          area: areas[item],
        });
      } else {
        changedViewpointRef.current = true;
        setPositionState({
          step: "stationary_01start",
          object: model?.getObjectByName(endPoint) as THREE.Object3D
        });
      }
    }
  };

  return (
    upSM ? (
      <div
        style={{
          flex: "1 0 0",
          margin: "0 16px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          visibility: !intro1Finished ? "hidden" : undefined,
          minHeight: sizeHeader+"px",
        }}
      >
        {
          Object.keys(areas).map(item => {
            const isSelected = currArea === areas[item] && !showOverview;
            return areas[item].showInMenu ? (
              <div
                key={item}
                style={{
                  position: "relative",
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {
                  showOverview ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "calc(100% + 1px)",
                        left: 0,
                        right: 0,
                        height: `${topMenuColorBarHeight}px`,
                        overflow: "visible",
                        backgroundColor: convertRGB255ToCss(areas[item].color),
                      }}
                    />
                  ) : null
                }
                <Button
                  onClick={() => handleMenuItemClick(item)}
                  style={{
                    color: isSelected ? "white" : "rgb(180, 180, 180)",
                    borderRadius: "0",
                    fontSize: fontSizeHeaderButtons,
                    fontFamily: "SamsungOne-Bold",
                    padding: "0",
                  }}
                >
                  {areas[item].name}
                </Button>
              </div>
            ) : null;
          })
        }
        <div
          style={{
            position: "relative",
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {
            showOverview ? (
              <div
                style={{
                  position: "absolute",
                  top: "calc(100% + 1px)",
                  left: 0,
                  right: 0,
                  height: `${topMenuColorBarHeight}px`,
                  overflow: "visible",
                  backgroundColor: "rgb(180, 180, 180)",
                }}
              />
            ) : null
          }
          <Button
            style={{
              fontFamily: "SamsungOne-Bold",
              color: showOverview ? "white" : "rgb(180, 180, 180)",
              borderRadius: "0",
              fontSize: fontSizeHeaderButtons,
              padding: "0",
            }}
            onClick={() => {
              setAnchorEl(null);
              toggleOverview();
            }}
          >
            Overview
          </Button>
        </div>
      </div>
    ) : (
      <div
        style={{
          flex: "1 0 0",
          margin: "0 16px",
          visibility: !intro1Finished ? "hidden" : undefined,
          minHeight: sizeHeader+"px",
          maxHeight: sizeHeader+"px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px 0",
        }}
      >
        <Button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
          style={{
            fontFamily: "SamsungOne-Bold",
            fontSize: "10pt",
          }}
        >
          { !sidebarState.area || showOverview ? "Bereich wählen" : sidebarState.area.name }
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {
            Object.keys(areas).map(item => {
              const isSelected = currArea === areas[item] && !showOverview;
              return areas[item].showInMenu ? (
                <MenuItem
                  key={item}
                  onClick={() => {handleMenuItemClick(item);}}
                >
                  <div
                    style={{
                      fontFamily: "SamsungOne-Bold",
                      fontSize: "10pt",
                      color: isSelected ? "white" : "rgb(180, 180, 180)",
                    }}
                  >
                    {areas[item].name}
                  </div>
                </MenuItem>
              ) : null;
            })
          }
          <MenuItem
            style={{
              fontFamily: "SamsungOne-Bold",
              fontSize: "10pt",
              color: showOverview ? "white" : "rgb(180, 180, 180)",
            }}
            onClick={() => {
              setAnchorEl(null);
              toggleOverview();
            }}
          >
            Overview
          </MenuItem>
        </Menu>
      </div>
    )
  )
}