import { useMediaQuery, useTheme } from "@material-ui/core";

export const useResponsiveFontSize = (lgFontSizePt: number) => {
  const theme = useTheme();
  // const upXS = useMediaQuery(theme.breakpoints.up("xs"));
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  const upMD = useMediaQuery(theme.breakpoints.up("md"));
  const upLG = useMediaQuery(theme.breakpoints.up("lg"));
  return upLG ? lgFontSizePt+"pt" : (upMD ? 0.71*lgFontSizePt+"pt" : (upSM ? 0.52*lgFontSizePt+"pt" : 0.31*lgFontSizePt+"pt"));
}

export const useResponsiveSize = (lgSize: number) => {
  const theme = useTheme();
  // const upXS = useMediaQuery(theme.breakpoints.up("xs"));
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  const upMD = useMediaQuery(theme.breakpoints.up("md"));
  const upLG = useMediaQuery(theme.breakpoints.up("lg"));
  return upLG ? lgSize : (upMD ? 0.71*lgSize : (upSM ? 0.6*lgSize : 1*lgSize));
}
