import React, { useEffect, useState } from "react";
import { UseCase } from "./App";
// @ts-ignore
import videoIndex from './data/videos/videoIndex.js';

type UseCaseAreaViewerProps = {
  currUseCase: UseCase | undefined,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  showProducts: boolean
  setShowProducts: React.Dispatch<React.SetStateAction<boolean>>,
};

export default function UseCaseAreaViewer({currUseCase, setCurrUseCase, showProducts, setShowProducts}: UseCaseAreaViewerProps) {
  const fadeTime = 0.5;
  const [videoOpacity, setVideoOpacity] = useState(0);
  const [visibleUseCase, setVisibleUseCase] = useState<UseCase | undefined>();
  useEffect(() => {
    if(currUseCase) {
      setVisibleUseCase(currUseCase);
      setTimeout(() => {
        setVideoOpacity(1);
      }, 1);
    }
    else {
      setVideoOpacity(0);
      setTimeout(() => {
        setVisibleUseCase(currUseCase);
      }, fadeTime*1000);
    }
  }, [currUseCase]);
  return (
    <>
      {
        visibleUseCase ? (
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 3,
                backgroundColor: "black",
              }}
            >
              <video
                style={{
                  width: "100%",
                  backgroundColor: "black",
                  opacity: videoOpacity,
                  transition: `all ${fadeTime}s`,
                }}
                src={videoIndex.index[visibleUseCase.id]?.fileName}
                controls
                autoPlay
                onEnded={() => {
                  setVideoOpacity(0);
                  setShowProducts(true);
                  setTimeout(() => {
                    setVisibleUseCase(undefined);
                  }, fadeTime*1000);
                }}
              />
            </div>
          </>
        ) : null
      }
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          opacity: videoOpacity,
          transition: `all ${fadeTime}s`,
          pointerEvents: "none",
        }}
      />
    </>
  );
}