import React, { useRef } from 'react';
import { convertRGB255ToCss } from '../colorHelpers';
import { IconButton, Paper, Typography } from '@material-ui/core';
import { ProductInfos, sidebarStateType, UseCase } from '../App';
import CancelIcon from '@material-ui/icons/Cancel';
import { useMemo } from 'react';
import ProductsSlide from './ProductsSlide';
import UseCaseSelector from './UseCaseSelector';
import { animate, MotionValue } from 'framer-motion';
import { useResponsiveSize } from '../useResponsiveFontSize';

type ExitButtonProps = {
  onClick: () => void,
  currAreaColor?: string,
};

function ExitButton({onClick, currAreaColor}: ExitButtonProps) {
  const iconScale = useResponsiveSize(1.33);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        zIndex: 6,
      }}
    >
      <IconButton
        onClick={onClick}
        style={{
          padding: 0,
          marginRight: `25px`,
          pointerEvents: "all",
          transform: `translate(0, -50%) scale(${iconScale})`,
        }}
      >
        <CancelIcon
          style={{
            backgroundColor: currAreaColor,
            borderRadius: "50%",
            borderWidth: "10px",
          }}
        />
      </IconButton>
    </div>
  );
}

type SidebarProps = {
  currUseCase: UseCase | undefined,
  sidebarState: sidebarStateType,
  setSidebarState: React.Dispatch<React.SetStateAction<sidebarStateType>>,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  productInfos: ProductInfos,
  showProducts: boolean,
  updateSidebar: (state: "open" | "close" | "opening" | "closing", mv?: MotionValue) => void,
};

const Sidebar = React.forwardRef(({currUseCase, sidebarState, setSidebarState, setCurrUseCase, productInfos, showProducts, updateSidebar} : SidebarProps, ref) => {
  const sideBarContentRef = useRef<HTMLDivElement>(null);

  const currAreaColor = useMemo(() => convertRGB255ToCss((sidebarState && sidebarState.state !== "close") ? sidebarState.area.color : undefined), [sidebarState]);
  const fontSizeHeader = useResponsiveSize(13.5);

  return (
    <Paper
      ref={ref}
      style={{
        overflowX: "hidden",
        overflowY: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        display: sidebarState?.state !== "close" ? "flex" : "none",
        border: "none",
        pointerEvents: (currUseCase && !showProducts) ? "none" : undefined,
        borderRadius: 0,
      }}
    >
      <div
        ref={sideBarContentRef}
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          height: "100%",
          width: "100%",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            backgroundColor: currAreaColor,
            padding: "12px 40px",
            display: "flex",
            alignItems: "center",
            zIndex: 6,
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontFamily: "SamsungSharpSans-Bold",
              fontSize: fontSizeHeader,
              color: sidebarState.area?.overrideTextColor ? convertRGB255ToCss(sidebarState.area?.overrideTextColor) : undefined,
            }}
          >
            {
              (sidebarState && sidebarState.state !== "close") ? sidebarState.area.name : undefined
            }
            {
              sidebarState.area?.type === "useCase" ? (
                <>
                  &nbsp;|&nbsp;
                  {
                    currUseCase ? currUseCase.name : "Use Cases"
                  }
                </>
              ) : null
            }
          </Typography>
        </div>
        {
          <>
            <ExitButton
              onClick={() => {
                if(currUseCase !== undefined) {
                  setCurrUseCase(undefined);
                } else {
                  if(!sidebarState || sidebarState?.state === "close") return;
                  const mv = new MotionValue(1);
                  animate(mv, 0, {
                    type: "tween",
                    duration: 500 / 1000,
                    onUpdate: () => {
                      updateSidebar("closing", mv);
                    },
                    onComplete: () => {
                      setSidebarState({
                        state: "close",
                        area: sidebarState.area,
                      });
                      updateSidebar("close");
                    },
                  });
                  setSidebarState({
                    state: "closing",
                    motionValue: mv,
                    area: sidebarState.area,
                  });
                }
                }}
              currAreaColor={sidebarState.area?.overrideTextColor ? convertRGB255ToCss(sidebarState.area?.overrideTextColor) : currAreaColor}
            />
          </>
        }
        {
          sidebarState.area?.type === "useCase" && !showProducts ? (
            <UseCaseSelector
              sidebarState={sidebarState}
              setCurrUseCase={setCurrUseCase}
              highlightColor={currAreaColor}
            />
          ) : null
        }
        {
          (currUseCase !== undefined) ? (
            <>
              <div
                style={{
                  flex: "1 0 0",
                  display: showProducts ? "block" : "none",
                }}
              >
                <ProductsSlide
                  products={currUseCase.productSlide.products}
                  highlightColor={currAreaColor}
                  productInfos={productInfos}
                  currUseCase={currUseCase}
                />
              </div>
            </>
          ) : null
        }
      </div>
    </Paper>
  );
});

export default Sidebar;
