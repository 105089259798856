import * as THREE from 'three';

type rgb255Type = {
  r: number,
  g: number,
  b: number,
};

const convertRGB255ToThreeJS = (rgb255: rgb255Type | undefined) => {
  return rgb255 ? new THREE.Color(
    rgb255.r/255,
    rgb255.g/255,
    rgb255.b/255
  ) : undefined;
};
export { convertRGB255ToThreeJS }

const convertRGB255ToCss = (rgb255: rgb255Type | undefined) => {
  return rgb255 ? `rgb(${rgb255.r}, ${rgb255.g}, ${rgb255.b})` : undefined;
};
export { convertRGB255ToCss }
