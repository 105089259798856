import React from 'react';
import { sidebarStateType, UseCase } from '../App';
// @ts-ignore
import targetGroupIndex from '../data/targetGroupImages/imageIndex.js';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useResponsiveSize } from '../useResponsiveFontSize';
import { useTheme, useMediaQuery } from '@material-ui/core';
import swoosh from '../data/images/swoosh.svg';

type UseCaseIconLayoutColumnProps = {
  useCases: UseCase[] | undefined,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  highlightColor: string | undefined,
};

function UseCaseIconLayoutColumn({useCases, setCurrUseCase, highlightColor}: UseCaseIconLayoutColumnProps) {
  const theme = useTheme();
  const upMD = useMediaQuery(theme.breakpoints.up("md"));
  return useCases ? (
    <>
      <div
        style={{
          flex: "0.1 0 0",
        }}
      />
      <div
        style={{
          flex: "1 0 0",
          display: "flex",
          flexDirection: "column",
          padding: upMD ? "0 25% 0 25%" : "0 20% 0 20%",
        }}
      >
        {
          useCases.map((useCase, i) => (
            <SingleUseCase
              key={i}
              onClick={() => setCurrUseCase(useCase)}
              targetGroupImage={targetGroupIndex.index[useCase.targetGroup].fileName}
              highlightColor={highlightColor}
              useCaseName={useCase.name}
            />
          ))
        }
      </div>
      <div
        style={{
          flex: "0.5 0 0",
        }}
      />
    </>
  ) : null;
}

type UseCaseIconLayout3Props = {
  useCases: [UseCase, UseCase, UseCase] | undefined,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  highlightColor: string | undefined,
};

function UseCaseIconLayout3({useCases, setCurrUseCase, highlightColor}: UseCaseIconLayout3Props) {
  return useCases ? (
    <>
      <div
        style={{
          flex: "0.1 0 0",
        }}
      />
      <div
        style={{
          flex: "1 0 0",
          display: "flex",
          flexDirection: "row",
          padding: "0 30px 0 40px",
        }}
      >
        <div
          style={{
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[0])}
            targetGroupImage={targetGroupIndex.index[useCases[0].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[0].name}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[1])}
            targetGroupImage={targetGroupIndex.index[useCases[1].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[1].name}
          />
        </div>
        <div
          style={{
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[2])}
            targetGroupImage={targetGroupIndex.index[useCases[2].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[2].name}
          />
        </div>
      </div>
      <div
        style={{
          flex: "0.5 0 0",
        }}
      />
    </>
  ) : null;
}

type UseCaseIconLayout4Props = {
  useCases: [UseCase, UseCase, UseCase, UseCase] | undefined,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  highlightColor: string | undefined,
};

function UseCaseIconLayout4({useCases, setCurrUseCase, highlightColor}: UseCaseIconLayout4Props) {
  return useCases ? (
    <>
      <div
        style={{
          flex: "0.1 0 0",
        }}
      />
      <div
        style={{
          flex: "1 0 0",
          display: "flex",
          flexDirection: "row",
          padding: "0 30px 0 40px",
        }}
      >
        <div
          style={{
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <SingleUseCaseSkip />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[0])}
            targetGroupImage={targetGroupIndex.index[useCases[0].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[0].name}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[1])}
            targetGroupImage={targetGroupIndex.index[useCases[1].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[1].name}
          />
        </div>
        <div
          style={{
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[2])}
            targetGroupImage={targetGroupIndex.index[useCases[2].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[2].name}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[3])}
            targetGroupImage={targetGroupIndex.index[useCases[3].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[3].name}
          />
          <SingleUseCaseSkip />
        </div>
      </div>
      <div
        style={{
          flex: "0.5 0 0",
        }}
      />
    </>
  ) : null;
}

type UseCaseIconLayout5Props = {
  useCases: [UseCase, UseCase, UseCase, UseCase, UseCase] | undefined,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  highlightColor: string | undefined,
};

function UseCaseIconLayout5({useCases, setCurrUseCase, highlightColor}: UseCaseIconLayout5Props) {
  return useCases ? (
    <>
      <div
        style={{
          flex: "0.1 0 0",
        }}
      />
      <div
        style={{
          flex: "1 0 0",
          display: "flex",
          flexDirection: "row",
          padding: "0 30px 0 40px",
        }}
      >
        <div
          style={{
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[0])}
            targetGroupImage={targetGroupIndex.index[useCases[0].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[0].name}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[1])}
            targetGroupImage={targetGroupIndex.index[useCases[1].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[1].name}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[2])}
            targetGroupImage={targetGroupIndex.index[useCases[2].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[2].name}
          />
        </div>
        <div
          style={{
            flex: "1 0 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <SingleUseCaseSkip
            height={"50%"}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[3])}
            targetGroupImage={targetGroupIndex.index[useCases[3].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[3].name}
          />
          <SingleUseCase
            onClick={() => setCurrUseCase(useCases[4])}
            targetGroupImage={targetGroupIndex.index[useCases[4].targetGroup].fileName}
            highlightColor={highlightColor}
            useCaseName={useCases[4].name}
          />
          <SingleUseCaseSkip
            height={"50%"}
          />
        </div>
      </div>
      <div
        style={{
          flex: "0.5 0 0",
        }}
      />
    </>
  ) : null;
}

type SingleUseCaseProps = {
  onClick: () => void,
  targetGroupImage: string,
  highlightColor: string | undefined,
  useCaseName: string,
};

function SingleUseCase({onClick, targetGroupImage, highlightColor, useCaseName}: SingleUseCaseProps) {
  const fontSizeUseCase = useResponsiveSize(13);
  const theme = useTheme();
  const upLG = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <div
      style={{
        width: "100%",
        paddingTop: "80%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: upLG ? "flex-start" : "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${targetGroupImage})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "70%",
            paddingTop: "75%",
            display: "flex",
            pointerEvents: "all",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
        </div>
        <div
            style={{
              position: "absolute",
              bottom: "10%",
              left: upLG ? "5%" : "15%",
              width: "100%",
              height: "20%",
              display: "flex",
              pointerEvents: "none",
            }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: upLG ? "flex-start" : "center",
            }}
          >
            <div
              style={{
                width: "5%",
              }}
            />
            <div
              style={{
                width: "10%",
                paddingTop: "10%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <ArrowForwardIcon
                  fontSize="small"
                  style={{
                    color: "white",
                    backgroundColor: highlightColor,
                    borderRadius: "50%",
                    width: "100%",
                    height: "100%",
                    transform: upLG ? undefined : "scale(0.75)"
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "80%",
                position: "relative",
                top: 0,
                left: "3%",
              }}
            >
              {
                useCaseName.split("\n").map((name, i) => (
                    <div
                      key={i}
                      style={{
                        fontFamily: "SamsungSharpSans-Bold",
                        fontSize: fontSizeUseCase,
                      }}
                    >
                      {
                        name
                      }
                    </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type SingleUseCaseSkipProps = {
  height?: string,
};

function SingleUseCaseSkip({height}: SingleUseCaseSkipProps) {
  return (
    <div
      style={{
        width: "100%",
        paddingTop: height === undefined ? "30%" : height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    />
  );
}

type UseCaseSelectorProps = {
  sidebarState: sidebarStateType | undefined,
  setCurrUseCase: React.Dispatch<React.SetStateAction<UseCase | undefined>>,
  highlightColor: string | undefined,
};

export default function UseCaseSelector({sidebarState, setCurrUseCase, highlightColor}: UseCaseSelectorProps) {
  const fontSizeBitteAuswaehlen = useResponsiveSize(10.8);
  const theme = useTheme();
  const upLG = useMediaQuery(theme.breakpoints.up("lg"));
  const upSM = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 0 0",
        alignContent: "flex-start",
        backgroundImage: (sidebarState?.area?.type === "interactive") ? undefined : `url(${swoosh})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingBottom: "125px",
      }}
    >
      <div
        style={{
          fontFamily: "SamsungSharpSans-Bold",
          textAlign: "left",
          padding: "0 40px",
          marginTop: "15px",
          fontSize: fontSizeBitteAuswaehlen,
        }}
      >
        Bitte wähle einen Use Case aus.
      </div>
      {
        (sidebarState && sidebarState.area?.type === "useCase" && sidebarState.state !== "close") ? <>
        {
          upLG || !upSM ? (
            <>
              {
                sidebarState.area.useCases.length === 3 ? (
                  <UseCaseIconLayout3
                    useCases={sidebarState.area.useCases as [UseCase, UseCase, UseCase]}
                    setCurrUseCase={setCurrUseCase}
                    highlightColor={highlightColor}
                  />
                ) : null
              }
              {
                sidebarState.area.useCases.length === 4 ? (
                  <UseCaseIconLayout4
                    useCases={sidebarState.area.useCases as [UseCase, UseCase, UseCase, UseCase]}
                    setCurrUseCase={setCurrUseCase}
                    highlightColor={highlightColor}
                  />
                ) : null
              }
              {
                sidebarState.area.useCases.length === 5 ? (
                  <UseCaseIconLayout5
                    useCases={sidebarState.area.useCases as [UseCase, UseCase, UseCase, UseCase, UseCase]}
                    setCurrUseCase={setCurrUseCase}
                    highlightColor={highlightColor}
                  />
                ) : null
              }
              {
                sidebarState.area.useCases.length < 3 || sidebarState.area.useCases.length > 5 ? (
                  <div>
                    Layout not implemented
                  </div>
                ) : null
              }
            </>
          ) : (
            <UseCaseIconLayoutColumn
              useCases={sidebarState.area.useCases}
              setCurrUseCase={setCurrUseCase}
              highlightColor={highlightColor}
            />
          )
        }
        </> : null
      }
    </div>
  );
}
