import { Ring } from '@react-three/drei';
import Graph from 'node-dijkstra';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { PositionState } from './PhotosphereViewer';

type GroundCursorProps =  {
  model: THREE.Group | undefined,
  setMoveToPointerDirectionFunc: React.Dispatch<React.SetStateAction<() => void>>,
  positionState: PositionState | undefined,
  setPositionState: React.Dispatch<React.SetStateAction<PositionState | undefined>>,
  route: Graph | undefined,
};
export default function GroundCursor({model, setMoveToPointerDirectionFunc, positionState, setPositionState, route}: GroundCursorProps) {
  const { gl, raycaster, invalidate, camera } = useThree();

  const currPointerPos = useRef<THREE.Vector2>();
  const wallContainer = useMemo(() => model?.getObjectByName("walls"), [model]);
  const floorContainer = useMemo(() => model?.getObjectByName("floorPlanes"), [model]);

  const objectCursorTransRef = useRef<{
    pos: THREE.Vector3,
    normal: THREE.Vector3,
   } | null>(null);

   const [onFloor, setOnFloor] = useState(false);

  useEffect(() => {
    if(!floorContainer) return;
    floorContainer.traverse(o => o.updateMatrixWorld());
  }, [floorContainer]);

  useEffect(() => {
    if(!wallContainer) return;
    wallContainer.traverse(o => {
      if(!o.hasOwnProperty("material")) return;
      const mesh = o as THREE.Mesh;
      mesh.material = new THREE.MeshStandardMaterial({
        colorWrite: false,
      });
    });
    invalidate();
  }, [wallContainer, invalidate]);

  useEffect(() => {
    const mouseMoveListener = (event: MouseEvent) => {
      currPointerPos.current = new THREE.Vector2(event.clientX, event.clientY);
      invalidate();
    };
    const touchMoveListener = (event: TouchEvent) => {
      event.preventDefault();
      const rect = gl.domElement.getBoundingClientRect();
      currPointerPos.current = new THREE.Vector2(rect.left + rect.width/2, rect.top + rect.height/2);
      invalidate();
    };

    gl.domElement.addEventListener("mousemove", mouseMoveListener);
    gl.domElement.addEventListener("touchmove", touchMoveListener);

    return () => {
      gl.domElement.removeEventListener("mousemove", mouseMoveListener);
      gl.domElement.removeEventListener("touchmove", touchMoveListener);
    }
  }, [gl, raycaster, wallContainer, floorContainer, invalidate, camera]);

  const photosphereGroup = useMemo(() => model?.getObjectByName("Kameras"), [model]);
  useEffect(() => {
    const f = () => {
      if(!objectCursorTransRef.current || !photosphereGroup || positionState?.step !== "stationary_03done" || !route) return;
      const worldPos = camera.getWorldPosition(new THREE.Vector3());
      const motionDir = objectCursorTransRef.current.pos.clone().sub(worldPos);
      motionDir.y = 0;
      if(motionDir.length() > 3) {
        motionDir.setLength(3);
      }
      const targetPos = worldPos.clone().add(motionDir);
      const distances = photosphereGroup.children.map(object => ({
        object,
        dist: object.getWorldPosition(new THREE.Vector3()).distanceTo(targetPos),
      })).sort((a, b) => a.dist - b.dist);
      if(distances[0].object === positionState.object) return;
      const path: string[] = route.path(positionState.object.name, distances[0].object.name);
      setPositionState({
        step: "moving_01start",
        objects: path.map(n => photosphereGroup?.getObjectByName(n) as THREE.Object3D),
        orientCamAlongPath: false,
      });
    };
    setMoveToPointerDirectionFunc(() => f);
    return () => {
      setMoveToPointerDirectionFunc(() => {});
    };
  }, [setMoveToPointerDirectionFunc, camera, photosphereGroup, positionState, route, setPositionState]);

  useFrame(() => {
    if(floorContainer && wallContainer && currPointerPos.current) {
      const raycaster = new THREE.Raycaster();
      const rect = gl.domElement.getBoundingClientRect();
      raycaster.setFromCamera({
        x: ((currPointerPos.current.x-rect.left) / rect.width) * 2 - 1,
        y: -((currPointerPos.current.y-rect.top) / rect.height) * 2 + 1,
      }, camera);
      const hits = raycaster.intersectObjects([
        ...floorContainer.children,
        ...wallContainer.children
      ]);
      if(hits.length > 0) {
        const normalMatrix = new THREE.Matrix3().getNormalMatrix(hits[0].object.matrixWorld);
        const normal = hits[0].face?.normal.clone();

        objectCursorTransRef.current = {
          pos: hits[0].point.clone(),
          normal: normal ? normal.applyMatrix3(normalMatrix) : new THREE.Vector3(0, 1, 0),
        }
        setOnFloor(hits[0].object.parent === floorContainer);
      } else {
        objectCursorTransRef.current = null;
      }
    }

    if(cursorRef.current) {
      if(objectCursorTransRef.current) {
        cursorRef.current.visible = true;
        cursorRef.current.position.copy(objectCursorTransRef.current.pos);
        cursorRef.current.lookAt(objectCursorTransRef.current.pos.clone().add(objectCursorTransRef.current.normal));
      } else {
        cursorRef.current.visible = false;
      }
    }
  });

  const cursorRef = useRef<THREE.Mesh>();
  const sceneRef = useRef<THREE.Scene>();
  const wallSceneRef = useRef<THREE.Scene>();

  useEffect(() => {
    if(!wallSceneRef.current) return;
    const wallScene = wallSceneRef.current;
    if(wallContainer) wallScene.add(wallContainer);
    return () => {
      if(wallContainer) wallScene.remove(wallContainer);
    }
  }, [wallSceneRef, wallContainer, floorContainer]);

  useFrame(state => {
    if(!sceneRef.current) return;
    state.gl.render(sceneRef.current, state.camera);
  }, 2);

  const radiusOut = 0.25;
  const radiusIn = 0.7 * radiusOut;

  return (
    <scene
      ref={sceneRef}
    >
      <group
        ref={cursorRef}
      >
        <group
          position={[0, 0, 0.01]}
          scale={onFloor ? [1, 1, 1] : [0.5, 0.5, 0.5]}
        >
          <Ring
            args={[(1-0.025)*radiusIn, radiusIn, 32]}
          >
            <meshBasicMaterial
              color={new THREE.Color(0.5, 0.5, 0.5).convertSRGBToLinear()}
              transparent={true}
              opacity={0.75}
            />
          </Ring>
          <Ring
            args={[radiusIn, radiusOut, 32]}
            renderOrder={1}
          >
            <meshBasicMaterial
              color={"white"}
              transparent={true}
              opacity={onFloor ? 0.75 : 0.5}
              side={THREE.DoubleSide}
            />
          </Ring>
          <Ring
            args={[radiusOut, 1.015*radiusOut, 32]}
          >
            <meshBasicMaterial
              color={new THREE.Color(0.5, 0.5, 0.5).convertSRGBToLinear()}
              transparent={true}
              opacity={0.75}
            />
          </Ring>
          {
            onFloor ? (
              <>
                <Ring
                  args={[1.035*radiusOut, 1.05*radiusOut, 32]}
                >
                  <meshBasicMaterial
                    color={new THREE.Color(0.5, 0.5, 0.5).convertSRGBToLinear()}
                    transparent={true}
                    opacity={0.75}
                  />
                </Ring>
                <Ring
                  args={[1.05*radiusOut, 1.1*radiusOut, 32]}
                  renderOrder={1}
                >
                  <meshBasicMaterial
                    color={"white"}
                    transparent={true}
                    opacity={0.75}
                  />
                </Ring>
                <Ring
                  args={[1.1*radiusOut, 1.115*radiusOut, 32]}
                >
                  <meshBasicMaterial
                    color={new THREE.Color(0.5, 0.5, 0.5).convertSRGBToLinear()}
                    transparent={true}
                    opacity={0.75}
                  />
                </Ring>
              </>
            ) : null
          }
        </group>
      </group>
      <scene
        ref={wallSceneRef}
        renderOrder={0}
      />
    </scene>
  );
}
