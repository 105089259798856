import { Circle, Ring } from '@react-three/drei';
import Graph from 'node-dijkstra';
import React, { useMemo, useRef, useState } from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { convertRGB255ToThreeJS } from './colorHelpers';
import { PositionState, useWorldTF } from './PhotosphereViewer/PhotosphereViewer';

type PhotosphereMarkerProps = {
  object: THREE.Object3D,
  color: {
    r: number,
    g: number,
    b: number,
  } | undefined,
  model: THREE.Group | undefined,
  onClick: () => void,
}
function PhotosphereMarker({object, color, model, onClick}: PhotosphereMarkerProps) {
  const [highlighted, setHighlighted] = useState(false);
  const [pos,] = useWorldTF(object);
  const currColor = useMemo(() => {
    let currColor = color ? new THREE.Color(color.r, color.g, color.b) : new THREE.Color(0.5, 0.5, 0.5);
    return currColor.convertSRGBToLinear();
  }, [color]);

  const { raycaster } = useThree();

  const floorContainer = useMemo(() => model?.getObjectByName("floorPlanes"), [model]);
  const floorHeight = useMemo(() => {
    if(!pos || !floorContainer?.children) return;
    floorContainer.updateMatrixWorld();
    raycaster.set(pos, new THREE.Vector3(0, -1, 0));
    const hits = raycaster.intersectObjects(floorContainer.children, true);
    if(hits.length <= 0) return pos.y;
    return hits[0].point.y;
  }, [floorContainer, pos, raycaster]);

  const radiusOut = 0.25;
  const radiusIn = 0.7 * radiusOut;

  return (
    <group
      rotation={[-Math.PI/2, 0, 0]}
      position={new THREE.Vector3(pos?.x, floorHeight !== undefined ? floorHeight : (pos?.y !== undefined ? pos.y - 1.65 : 0), pos?.z)}
    >
      <Circle
        args={[radiusOut*1.5, 32]}
        onPointerOver={() => setHighlighted(true)}
        onPointerOut={() => setHighlighted(false)}
        onClick={onClick}
        visible={false}
      />
      <Ring
        args={[radiusIn, radiusOut, 32]}
      >
        <meshBasicMaterial
          color={currColor}
          transparent={true}
          opacity={highlighted ? 0.75 : 0.3}
        />
      </Ring>
      <Ring
        args={[radiusOut, 1.025*radiusOut, 32]}
      >
        <meshBasicMaterial
          color={new THREE.Color(0.5, 0.5, 0.5).convertSRGBToLinear()}
          transparent={true}
          opacity={highlighted ? 0.75 : 0.3}
        />
      </Ring>
      <Ring
        args={[(1-0.025)*radiusIn, radiusIn, 32]}
      >
        <meshBasicMaterial
          color={new THREE.Color(0.5, 0.5, 0.5).convertSRGBToLinear()}
          transparent={true}
          opacity={highlighted ? 0.75 : 0.3}
        />
      </Ring>
    </group>
  );
}

type ViewpointMarkersProps = {
  model: THREE.Group | undefined,
  areas: {
    [key: string]: {
      color: {
        r: number,
        g: number,
        b: number,
      },
      viewpointObject: string,
    }
  },
  positionState: PositionState | undefined,
  setPositionState: React.Dispatch<React.SetStateAction<PositionState | undefined>>,
  route: Graph | undefined,
};

export default function ViewpointMarkers({model, areas, positionState, setPositionState, route}: ViewpointMarkersProps) {
  const photosphereGroup = useMemo(() => model?.getObjectByName("Kameras"), [model]);

  const sceneRef = useRef<THREE.Scene>();

  useFrame(state => {
    if(!sceneRef.current) return;
    state.gl.render(sceneRef.current, state.camera);
    state.gl.clearDepth();
  }, 3);

  return (
    <scene
      ref={sceneRef}
    >
      {
        photosphereGroup?.children.map((thisMarker, i) => {
          const currAreaKey = Object.keys(areas).find(k => areas[k].viewpointObject === thisMarker.name);
          const currColor = currAreaKey ? convertRGB255ToThreeJS(areas[currAreaKey].color) : new THREE.Color(1, 1, 1);
          return (
            <PhotosphereMarker
              key={i}
              object={thisMarker}
              color={currColor}
              model={model}
              onClick={() => {
                if(positionState?.step !== "stationary_03done" || !route) return;
                const path: string[] = route.path(positionState.object.name, thisMarker.name);
                setPositionState({
                  step: "moving_01start",
                  objects: path.map(n => model?.getObjectByName(n) as THREE.Object3D),
                  orientCamAlongPath: false,
                });
              }}
            />
          );
        })
      }
    </scene>
  );
}
