import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useThree } from 'react-three-fiber';
import mergeRefs from 'react-merge-refs';

type ControlsProps = {
  rotDamping?: number,
  zoomDamping?: number,
  rotSensitivity?: number,
  zoomSensitivity?: number,
  zoomMin?: number,
  zoomMax?: number,
  enabled?: boolean,
};

function normalizeAngle(angle: number) {
  let newAngle = angle;
  while (newAngle <= -Math.PI) newAngle += Math.PI*2;
  while (newAngle > Math.PI) newAngle -= Math.PI*2;
  return newAngle;
}

const Controls = React.forwardRef(({rotDamping = 0.75, zoomDamping = 0.9, rotSensitivity = 1, zoomSensitivity = 1, zoomMin = 30, zoomMax = 100, enabled}: ControlsProps, ref) => {
  const { camera, scene, invalidate, gl } = useThree();

  const camParent1Ref = useRef<THREE.Object3D>();
  const camParent2Ref = useRef<THREE.Object3D>();

  useEffect(() => {
    let currPointerPos: THREE.Vector2 | undefined;
    let prevPointerPos: THREE.Vector2 | undefined;
    let rotImpulse = new THREE.Vector2(0, 0);
    let zoomImpulse = 0;
    let pointerDown = false;
    let prevPinchFingerDist = 0;
    let currPinchFingerDist = 0;

    const mouseMoveListener = (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      event.cancelBubble=true;
      event.returnValue=false;
      currPointerPos = new THREE.Vector2(event.clientX, event.clientY);
      if(event.target !== gl.domElement) return;
      if(event.buttons === 0) {
        pointerDown = false;
      }
      if(event.buttons === 1) {
        pointerDown = true;
      }
      return false;
    };
    const mouseDownListener = (event: MouseEvent) => {
      pointerDown = true;
    };
    const mouseUpListener = (event: MouseEvent) => {
      pointerDown = false;
      prevPointerPos = undefined;
      currPointerPos = undefined;
    };
    const wheelListener = (event: WheelEvent) => {
      event.preventDefault();
      zoomImpulse += event.deltaY * 0.02 * zoomSensitivity;
    };

    const touchMoveListener = (event: TouchEvent) => {
      if(event.target !== gl.domElement) return;
      if(event.touches.length === 2) {
        pointerDown = true;
        currPinchFingerDist = Math.hypot(
          event.touches[0].clientX - event.touches[1].clientX,
          event.touches[0].clientY - event.touches[1].clientY
        );
        currPointerPos = new THREE.Vector2(
          event.touches[0].clientX + 1/2*(event.touches[1].clientX - event.touches[0].clientX),
          event.touches[0].clientY + 1/2*(event.touches[1].clientY - event.touches[0].clientY)
        );
      }
      if(event.touches.length === 1) {
        pointerDown = true;
        currPointerPos = new THREE.Vector2(event.touches[0].clientX, event.touches[0].clientY);
      }
    };
    const touchStartListener = (event: TouchEvent) => {
      if(event.touches.length === 1) {
        pointerDown = true;
      }
      if(event.touches.length === 2) {
        currPinchFingerDist = Math.hypot(
          event.touches[0].clientX - event.touches[1].clientX,
          event.touches[0].clientY - event.touches[1].clientY
        );
        prevPinchFingerDist = currPinchFingerDist;
      }
    };
    const touchEndListener = (event: TouchEvent) => {
      pointerDown = false;
      prevPinchFingerDist = 0;
      currPinchFingerDist = 0;
      prevPointerPos = undefined;
      currPointerPos = undefined;
    };

    const clock = new THREE.Clock();
    const update = () => {
      window.requestAnimationFrame(update);
      if(!enabled) return;

      const delta = clock.getDelta();
      if(prevPointerPos && currPointerPos && pointerDown) {
        const rotFac = (1 + delta) * rotSensitivity;
        const mouseDeltaRelContainer = prevPointerPos.clone().sub(currPointerPos);
        mouseDeltaRelContainer.x /= gl.domElement.width;
        mouseDeltaRelContainer.y /= gl.domElement.height;
        const addImpulse = mouseDeltaRelContainer.clone().multiplyScalar(rotFac);
        rotImpulse.add(addImpulse);
      }

      rotImpulse.multiplyScalar(rotDamping * (1 - delta));

      const cam = camera as THREE.PerspectiveCamera;
      zoomImpulse += (prevPinchFingerDist-currPinchFingerDist) * 0.02 * zoomSensitivity;
      zoomImpulse *= zoomDamping * (1 - delta);
      let distToLimit: number;
      if(zoomImpulse < 0) distToLimit = Math.max(cam.fov - zoomMin, 0);
      else {
        distToLimit = Math.max(zoomMax - cam.fov, 0);
      }
      const slowdownDist = 15;
      if(distToLimit < slowdownDist) {
        zoomImpulse *= Math.pow(distToLimit/slowdownDist, 0.1);
      }

      if(camParent1Ref.current && camParent2Ref.current) {
        if(rotImpulse.length() > 0.0001 || Math.abs(zoomImpulse) > 0.0001) {
          camParent1Ref.current.rotation.y += rotImpulse.x;
          camParent2Ref.current.rotation.x += rotImpulse.y;
          camParent1Ref.current.rotation.y = normalizeAngle(camParent1Ref.current.rotation.y);
          camParent2Ref.current.rotation.x = normalizeAngle(camParent2Ref.current.rotation.x);
          camParent2Ref.current.rotation.x = Math.min(Math.max(camParent2Ref.current.rotation.x, -3*Math.PI/16), 3*Math.PI/16);
          cam.fov += zoomImpulse;
          cam.updateProjectionMatrix();
          invalidate();
        }
      }

      prevPointerPos = currPointerPos?.clone();
      prevPinchFingerDist = currPinchFingerDist;
    }
    update();

    window.addEventListener("mousemove", mouseMoveListener);
    gl.domElement.addEventListener("mousedown", mouseDownListener);
    window.addEventListener("mouseup", mouseUpListener);
    gl.domElement.addEventListener("wheel", wheelListener);

    window.addEventListener("touchmove", touchMoveListener);
    gl.domElement.addEventListener("touchstart", touchStartListener);
    window.addEventListener("touchend", touchEndListener);
    return () => {
      window.removeEventListener("mousemove", mouseMoveListener);
      gl.domElement.removeEventListener("mousedown", mouseDownListener);
      window.removeEventListener("mouseup", mouseUpListener);
      gl.domElement.removeEventListener("wheel", wheelListener);

      window.removeEventListener("touchmove", touchMoveListener);
      gl.domElement.removeEventListener("touchstart", touchStartListener);
      window.removeEventListener("touchend", touchEndListener);
    }
  }, [invalidate, rotDamping, rotSensitivity, gl, camera, zoomDamping, zoomSensitivity, zoomMin, zoomMax, enabled]);

  useEffect(() => {
    if(!camParent2Ref.current) return;
    const prevParent = camera.parent || scene;
    camParent2Ref.current.attach(camera);
    camera.position.set(0, 0, 0);
    if(camParent1Ref.current) camParent1Ref.current.rotation.set(0, Math.PI*0.91, 0);

    return () => {
      prevParent.attach(camera);
    }
  }, [camera, scene]);

  return (
    <group
      ref={mergeRefs([camParent1Ref, ref])}
    >
      <group
        ref={camParent2Ref}
      >
      </group>
    </group>
  );
});
export default Controls;
